@font-face {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-display: auto;
      font-stretch: expanded;
      src: url(./Roboto.woff2) format('woff2'),
            url(./Roboto.woff) format('woff');
}

@font-face {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-display: auto;
      font-stretch: expanded;
      src: url(./Robotomedium.woff2) format('woff2'),
            url(./Robotomedium.woff) format('woff');
}

@font-face {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-display: auto;
      font-stretch: expanded;
      src: url(./Robotobold.woff2) format('woff2'),
            url(./Robotobold.woff) format('woff');
}


/* @font-face {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-display: auto;
      font-stretch: expanded;
      src: url(./Montserrat-Regular.woff2) format('woff2'),
            url(./Montserrat-Regular.woff) format('woff');
}

@font-face {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-display: auto;
      font-stretch: expanded;
      src: url(./Montserrat-SemiBold.woff2) format('woff2'),
            url(./Montserrat-SemiBold.woff) format('woff');
}

@font-face {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 700;
      font-display: auto;
      font-stretch: expanded;
      src: url(./Montserrat-Bold.woff2) format('woff2'),
            url(./Montserrat-Bold.woff) format('woff');
} */