.icon {
  border: 0.1px solid red;
  box-sizing: border-box;
  height: fit-content;
  width: fit-content;
  margin: 0;
  padding: 0;
}

.icon_svg {
  border: 0.1px solid blue;
  box-sizing: border-box;
  height: fit-content;
  width: fit-content;
  margin: 0;
  padding: 0;
}
