.footer {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px 24px 24px;
    background-color: var(--white);
    box-shadow: var(--box-shadow-top);
    margin-top: auto;
    gap: 16px
}

.footer__icons_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
}

.footer__icon {
    height: 24px;
    width: 24px;
}

.footer__icon_title {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: var(--font-size-12);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-regular);
    color: var(--dark-grey);
    margin: 0;
    padding: 0;
    text-align: center;
}

.footer__icon_title_active {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: var(--font-size-12);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-regular);
    color: var(--blue);
    margin: 0;
    padding: 0;
    text-align: center;
}