.order_create {}

.order_create__form {
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    margin: 0;
    padding: 12px 24px 24px 24px;
}

.order_create__title {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: var(--font-size-16);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-medium);
    color: var(--black);
    margin: 0 0 16px 0;
    padding: 0;
    text-align: center;
}

.order_create__date_container {
    display: flex;
    justify-content: space-between;
    align-self: flex-start;
    gap: 16px
}

.order_create__container {
    position: relative;
    margin: 6px 0 6px 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.order_create__input_title {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: var(--font-size-12);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-regular);
    color: var(--dark-grey);
    margin: 0;
    padding: 2px 4px 2px 4px;
    background-color: var(--white);
    position: absolute;
    top: -12px;
    left: 10px;
    z-index: 2;
}

.order_create__input {
    display: flex;
    height: 40px;
}

.order_create__button_container {
    height: 6px;
}

.order_create__title {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: var(--font-size-16);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-medium);
    color: var(--black);
    background-color: var(--white);
    margin: 0;
    padding: 10px 0 0 0;
}

.order_create__icon_checkbox_container {
    display: flex;
    justify-content: space-between;
    margin: 0 0 8px 0;
    padding: 0;
}

.order_create__order_icon {}

.order_create__icon_title {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: var(--font-size-16);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-regular);
    color: var(--dark-grey);
    margin: 0 0 0 10px;
    padding: 0;
    align-self: start;
    width: 180px;
}

.order_create__checkbox {
    margin: 0;
    padding: 0;
}

.ant-form-item {
    margin: 0;
    padding: 0;
}

.order_create__photo_title {
    margin: 0;
    padding: 0;
}

.order_create__transport_container {
    position: relative;
    margin: 6px 0 0 0;
}

.order_create__common_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    border: 1px solid var(--dark-grey);
    border-radius: 5px;
    margin: 0;
    padding: 20px 0px 0px 16px;
}

.order_create__common_title {
    align-self: center;
    margin: 0;
    padding: 0;
}

.order_create__icons_radio_container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin: 0;
    padding: 0;
}

.order_create__icons_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0;
    padding: 0;
}

.order_create__radio_container {
    display: flex;
    flex-direction: column;
    gap: 14px
}

.order_create__icon_container {
    display: flex;
    margin: 0;
    padding: 0;
}

.order_create__order_icon {}



.datepicker {
min-width: 140px !important;
  }
  
  .datepicker .ant-picker-time-panel, 
  .datepicker .ant-picker-date-panel {
    width: 200px !important; /* Сужаем ширину таблицы с выбором даты/времени */
  }
  
  .datepicker .ant-picker-cell-inner {
    font-size: 12px; /* Можно настроить размер текста для компактности */
  }