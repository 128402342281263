.start {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
}

.start__first_container {
  width: 100%;
  height: 160px;
}

.start__first_img {
  background: url('../../images/city_image.png') center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.start__img_text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-38);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-black);
  color: var(--white);
  background-color: var(--white-hover);
  text-align: center;
  text-justify: inter-cluster;
  text-shadow: 5px 5px 4px var(--black-hover);
}

.start__courier_container {
  margin: 0;
  padding: 24px 24px 0 24px;
}

.start__courier_title {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-24);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  color: var(--light-blue);
  text-align: end;
}

.start__icons_text_container {
  display: flex;
  gap: 10px;
  margin: 0 0 16px 0;
  padding: 0;
}

.start__icons_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.start__texts_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  line-height: 24px;
}

.start__icon_text {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-16);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-medium);
  color: var(--light-blue);
}

.start__mission_container {
  width: 100%;
  height: 286px;
}

.start__mission_img {
  background: url('../../images/city_image.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.start__mission_back {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 286px;
  background-color: var(--white-hover);
}

.start__mission {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 126px;
  background-color: var(--blue-back);
  margin: 0;
  padding: 24px;
}

.start__mission_icons_title_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.start__mission_icons_container {
  display: flex;
  gap: 4px;
}

.start__mission_title {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-24);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  color: var(--white);
  text-align: end;
}

.start__mission_text {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-16);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-medium);
  color: var(--white);
}

.start__work_container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0;
  padding: 24px;
}

.start__work_title {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-24);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  color: var(--light-blue);
}

.start__types_container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 32px;
}

.start__type_container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.start__work_type {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-16);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-medium);
  color: var(--light-blue);
}

.start__work_item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.start__work_point {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-12);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  color: var(--light-blue);
}

.start__sender_container {
  width: 100%;
  height: 470px;
}

.start__sender_img {
  background: url('../../images/city_image.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.start__sender_back {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 470px;
  background-color: var(--white-hover);
}

.start__sender {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  height: 310px;
  background-color: var(--blue-back);
  margin: 0;
  padding: 24px;
}

.start__sender_icon_text_container {
  display: flex;
  gap: 10px;
}

.start__sender_icon {
  height: 24px;
  width: 24px;
}

.start__sender_icons_container {
  display: flex;
  gap: 4px;
}

.start__sender_title {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-24);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  color: var(--white);
  text-align: end;
}

.start__sender_text {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-16);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-medium);
  color: var(--white);
}

.start__contacts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--light-green);
  margin: 0;
  padding: 24px;
}

.start__contacts_text_container {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  gap: 8px;
  margin: 10px 0 0 0;
}

.start__contacts_title {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-20);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-medium);
  color: var(--light-blue);
  text-decoration: underline;
  cursor: pointer;
  margin: 0 0 10px 0;
}

.start__contacts_text_large {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-14);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  color: var(--light-blue);
}

.start__contacts_text {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-12);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  color: var(--light-blue);
}

.start__contacts_icons_container {
  display: flex;
  gap: 12px;
}

.start__contacts_icon {
  height: 32px;
  cursor: pointer;
}
