.forgot_password {
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    margin: 0;
    padding: 48px 0 0 0;
}

.forgot_password__title {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: var(--font-size-24);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-bold);
    color: var(--black);
    margin: 0 0 16px 0;
    padding: 0;
    text-align: center;
}

.forgot_password__text {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: var(--font-size-16);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-regular);
    color: var(--black);
    margin: 0;
    padding: 0;
    text-align: center;
    width: 300px;
    align-self: center;
}