.container {
  display: flex;
  justify-content: space-between;
  justify-self: left;
  gap: 8px;
  margin: 8px;
  padding: 8px 24px;
  background-color: var(--white);
  border-radius: 5px;
  width: 70%;
}

.left {
  align-self: start;
}

.right {
  align-self: end;
}
