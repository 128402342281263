.detailed_message {
  margin: 0;
  padding: 24px;
  background-color: var(--white);
}

.detailed_message__main_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.detailed_message__price {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-16);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  color: var(--light-green);
  margin: 0;
  padding: 0;
  width: 100px;
  text-align: start;
}

.detailed_message__link {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-12);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  color: var(--light-blue);
  margin: 0;
  padding: 0;
  width: 100px;
  text-align: end;
}

.detailed_message__picture_description_container {
  display: flex;
}

.detailed_message__picture_container {
  height: 83px;
  width: 64px;
}

.detailed_message__text_container {
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin: 24px 0 0 0;
}

.message_fromto {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-12);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  color: var(--light-grey);
  margin: 0 10px 0 0;
}

.detailed_message__from_town_date_container {
  display: flex;
  flex-direction: column;
  margin: 0 0 12px 0;
}

.detailed_message__to_town_date_container {
  display: flex;
  flex-direction: column;
}

.detailed_message__town {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-12);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  line-height: 14px;
  color: var(--black);
  word-break: break-all;
  text-align: start;
}

.detailed_message__date_container {
  display: flex;
  gap: 4px;
}

.detailed_message__date {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-12);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  color: var(--black);
}

.detailed_message__time {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-12);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  color: var(--dark-grey);
}

.detailed_message__button {
  border: none;
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  padding: 0;
  margin: 0;
}
