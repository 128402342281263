.price_offer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
}

.price_offer__title {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: var(--font-size-16);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  color: var(--black);
  text-align: center;
}

.price_offer__note {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: var(--font-size-16);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-medium);
  color: var(--black);
  text-align: center;
}

.price_offer__button_container {
  display: flex;
  gap: 16px;
}
