.message_card {
  display: flex;
  flex-direction: column;
  padding: 16px 24px 16px 16px;
  background-color: var(--white);
  box-shadow: var(--box-shadow);
  position: 'relative';
}

.message_card__button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
  position: relative;
}

.message_card__main_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px 12px;
}

.message_card__price {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-16);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  color: var(--light-green);
  width: 100px;
  text-align: start;
}

.message_card__timeinfo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-12);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  color: var(--dark-grey);
  width: 100px;
  text-align: right;
}

.message_card__description {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-12);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  color: var(--dark-grey);
  margin: 0 0 6px 0;
  width: 100px;
}

.message_card__picture_description_container {
  display: flex;
}

.message_card__picture_container {
  height: 83px;
  width: 64px;
}

.message_card__text_container {
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin: 24px 0 0 0;
}

.message_fromto {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-12);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  color: var(--light-grey);
  margin: 0 10px 0 0;
}

.message_card__from_town_date_container {
  display: flex;
  flex-direction: column;
  margin: 0 0 12px 0;
}

.message_card__to_town_date_container {
  display: flex;
  flex-direction: column;
}

.message_card__town {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-12);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  line-height: 14px;
  color: var(--black);
  word-break: break-all;
  text-align: start;
}

.message_card__date_container {
  display: flex;
  gap: 4px;
}

.message_card__date {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-12);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  color: var(--black);
}

.message_card__time {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-12);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  color: var(--dark-grey);
}

.message_container {
  display: flex;
  margin: 10px 0 0 0;
}

.message_author {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-14);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-medium);
  color: var(--light-blue);
  margin: 0 10px 0 0;
}

.message_last__message {
  font-family: 'Roboto', 'Arial', sans-serif;
  font-size: var(--font-size-14);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-medium);
  color: var(--black);
  margin: 0 10px 0 0;
}
