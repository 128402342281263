.profile {}

.profile__user_container {
    display: flex;
    align-items: center;
    background-color: var(--white);
    margin: 0 0 5px 0;
    padding: 24px;
    box-shadow: var(--box-shadow-bottom);
}

.profile__user_info_container {
    display: flex;
    flex-direction: column;
    margin: 0 0 0 12px;
    padding: 0;
}

.profile__user_pic {
    height: 80px;
    width: 80px;
    border-radius: 50%;
}

.profile__user_title {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: var(--font-size-16);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-regular);
    color: var(--black);
    margin: 0 0 4px 0;
    padding: 0;
}

.profile__user_info {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: var(--font-size-14);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-regular);
    color: var(--black);
    margin: 0 0 2px 0;
    padding: 0;
}

.profile__menu_link {
    text-decoration: none;
    color: var(--black);
    cursor: pointer;
}

.profile__menu_button {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: var(--font-size-16);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-regular);
    color: var(--black);
    text-decoration: none;
    color: var(--black);
    cursor: pointer;
    border: none;
    margin: 0;
    padding: 0;
}

.profile__menu_link_delete {
    text-decoration: none;
    color: var(--red);
    cursor: pointer;
}

.profile__menu_points {
    display: flex;
    flex-direction: column;
    box-shadow: var(--box-shadow-bottom);
    margin: 0 0 5px 0;
    padding: 0;
}