.section {
  display: flex;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
  height: 100%;
}

.text_container {
  display: flex;
  gap: 8px;
  padding: 8px 24px;
  background-color: var(--white);
}

.text_black {
  color: var(--black);
}

.text_blue {
  color: var(--light-blue);
}

.buttons {
  display: flex;
  gap: 16px;
}

.buttons_box {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px 24px 24px;
}

.button_input_box {
  display: flex;
  padding: 0 2px 2px 2px;
  gap: 2px;
}
