.search_results {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

.search_results__container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-height: calc(100vh - 222px);
    overflow-y: auto;
    margin: 0 0 10px 0;
    padding: 0;
}