.chat_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--background-grey);
  flex-grow: 1;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  background-color: var(--background-grey);
}
