.detailed_order {
  margin: 0;
  padding: 24px;
  background-color: var(--white);
}

.detailed_order__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  gap: 12px;
}

.detailed_order__main_container {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
}

.detailed_order__price {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: var(--font-size-24);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-bold);
  color: var(--light-green);
  margin: 0;
  padding: 0;
  width: 100px;
  text-align: start;
}

.detailed_order__button {
  border: none;
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.detailed_order__icon {
}

.detailed_order__icon_container {
  display: flex;
  justify-content: flex-end;
  width: 100px;
}

.detailed_order__adress_container {
}

.detailed_order__title {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: var(--font-size-16);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  color: var(--dark-grey);
}

.detailed_order__town {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: var(--font-size-16);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-medium);
  color: var(--black);
  margin: 0;
  padding: 0;
  word-break: break-all;
  text-align: start;
}

.detailed_order__street {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: var(--font-size-16);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  color: var(--black);
  margin: 0;
  padding: 0;
}

.detailed_order__date_container {
  display: flex;
  margin: 0;
  padding: 0;
}

.detailed_order__date {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: var(--font-size-16);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-medium);
  color: var(--black);
  margin: 0;
  padding: 0;
}

.detailed_order__time {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: var(--font-size-16);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  color: var(--dark-grey);
  margin: 0 0 0 8px;
  padding: 0;
}

.detailed_order__sender_container {
  display: flex;
  flex-direction: column;
}

.detailed_order__sender_name_container {
  display: flex;
  margin: 0;
  padding: 0;
}

.detailed_order__sender {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: var(--font-size-16);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-medium);
  color: var(--blue);
  margin: 0 0 0 8px;
  padding: 0;
}

.detailed_order__sender_rating {
  display: flex;
  align-items: flex-start;
}

.detailed_order__rating {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: var(--font-size-16);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  color: var(--dark-grey);
  margin: 0 0 0 4px;
  padding: 0;
}

.detailed_order__description {
  font-family: "Roboto", "Arial", sans-serif;
  font-size: var(--font-size-16);
  font-style: var(--font-style-normal);
  font-weight: var(--font-weight-regular);
  color: var(--black);
  margin: 0;
  padding: 0;
}

.detailed_order__photos_container {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.detailed_order__photo {
  height: 145px;
  width: 109px;
  margin: 0 8px 0 0;
  padding: 0;
}

.detailed_order__photo:last-of-type {
  height: 145px;
  width: 109px;
  margin: 0;
  padding: 0;
}

.detailed_order__buttons_container {
  display: flex;
  justify-content: space-between;
  margin: 6px 0 0 0;
  gap: 16px;
  padding: 0;
}
