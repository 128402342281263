.error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    margin: 0;
    padding: 24px;
    gap: 32px;
    background-color: var(--white);
}

.error_text_container {
    display: flex;
    flex-direction: column;
}

.error_text {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: var(--font-size-24);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-bold);
    color: var(--dark-grey);
    margin: 0;
    padding: 0;
    align-self: center;
}