.search_orders {
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    margin: 0;
    padding: 24px;
}

.search_orders__title {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: var(--font-size-16);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-medium);
    color: var(--black);
    margin: 0 0 16px 0;
    padding: 0;
    text-align: center;
}