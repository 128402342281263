.menu_component {
    display: flex;
    margin: 0;
    padding: 24px;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white);
}

.menu_component_container {
    display: flex;
    align-items: center;
}

.menu_component__icon {}

.menu_component__text {
    margin: 0 0 0 8px;
    padding: 0;
}