.modal {
    display: flex;
    justify-content: center;
    align-items: center; 
    position: fixed;
    top: 0; 
    left: 0;
    width: 100%; 
    height: 100%; 
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.modal_container {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center; 
    min-height: 320px;
    min-width: 320px;
    background-color: white;
    position: relative;
    border-radius: 40px;
    margin: 20px; 
    padding: 20px; 
}

.modal_close_icon {
    position: absolute;
    top: 20px; 
    right: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
