.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 18px;
    background-color: var(--white);
    box-shadow: var(--box-shadow-bottom);
    padding: 12px 18px;
    margin: 0 0 5px 0;
}

.header__icon {
    height: 40px;
    width: 40px;
}

.header__logo_button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
    margin: 0;
}

.header__logo {
    height: 40px;
    width: 161px;
}