.signup {}

.signup__form {
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    margin: 0;
    padding: 24px;
}

.signup__title {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: var(--font-size-16);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-medium);
    color: var(--black);
    margin: 0 0 16px 0;
    padding: 0;
    text-align: center;
}

.signup__container {
    position: relative;
    margin: 6px 0 6px 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.signup__input_title {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: var(--font-size-12);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-regular);
    color: var(--dark-grey);
    margin: 0;
    padding: 2px 4px 2px 4px;
    background-color: var(--white);
    position: absolute;
    top: -12px;
    left: 10px;
    z-index: 2;
}

.signup__input {
    display: flex;
    height: 40px;
}

.signup__button_container {
    height: 6px;
}

.signup__role_container {
    position: relative;
    margin: 6px 0 0 0;
}

.signup__title {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: var(--font-size-12);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-regular);
    color: var(--dark-grey);
    margin: 0;
    padding: 2px 4px 2px 4px;
    background-color: var(--white);
    position: absolute;
    top: -8px;
    left: 10px;
    z-index: 2;
}

.signup__icons_radio_container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    border: 1px solid var(--dark-grey);
    border-radius: 5px;
    margin: 0;
    padding: 20px 0px 0px 16px;
}

.signup__icons_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 0;
    padding: 0;
}

.signup__radio_container {
    display: flex;
    flex-direction: column;
    gap: 14px
}

.signup__icon_container {
    display: flex;
    align-items: center;
    width: 92px;
    justify-content: space-between;
    margin: 0;
    padding: 0;
}

.signup__order_icon {}

.signup__icon_title {
    font-family: 'Roboto', 'Arial', sans-serif;
    font-size: var(--font-size-16);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-regular);
    color: var(--dark-grey);
    margin: 0;
    padding: 0;
}

.signup__checkbox {
    margin: 0;
    padding: 0;
}

.ant-form-item {
    margin: 0;
    padding: 0;
}

.signup__checkbox {
    display: flex;
    justify-content: center;
    margin: 6px 0;
    padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; 
}

input[type=number] {
    -moz-appearance:textfield; 
}